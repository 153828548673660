<template>
  <v-dialog
      v-model="internalShow"
      width="420"
      data-testid="ex-user-account-selector"
      @click:outside="handleClose"
  >
    <v-card
        class="pa-6 ex-change-account-owner"
    >
      <div class="d-flex items-center mb-4">
        <span>
          <div>
            <ExAvatar
                v-if="user?.picture || user?.firstName"
                size="large"
                :picture="user?.picture || null"
                :firstName="user?.firstName || ''"
                :lastName="user?.lastName || ''"
            ></ExAvatar>
          </div>
        </span>
        <v-spacer/>
        <span class="align-self-center" @click="emit('onClose')">
          <v-btn
              icon
              color="grey"
          >
            <Icon class="cursor-pointer" name="popup_close"></Icon>
          </v-btn>
        </span>
      </div>
      <div class="mb-2">
        <h3 class="action_popup_title mb-0 mt-8">Resend Invite</h3>
      </div>
      <div>
        <div class="action_popup_message">
          You are about to resend invite to {{ `${user.firstName} ${user.lastName}.` }}
        </div>
        <br>
        <div class="action_popup_message">
          Please select the account from which you want to resend the invitation.
        </div>
      </div>
      <div class="flex-column justify-end mt-2">
        <ExSelectPicker
            pickerLabel="Select an Account"
            label="Pick an Account"
            searchLabel="Pick an Account"
            placeholder="Search for Account"
            :items="accounts"
            class="emp-picker-input"
            :value="selectedAccount"
            @item-selected="selectedAccount = $event"
            :hideInputLeftMargin="true"
        />
        <div class="d-flex justify-end">
          <Button
              class="d-flex justify-center align-center"
              style="height: 44px; border-radius: 8px;"
              :normal="selectedAccount ? 'normal-hover' : 'normal-disable'"
              label="Resend Invite"
              @onClick="$emit('selectAccount', selectedAccount)"
          ></Button>
        </div>
      </div>
    </v-card>
  </v-dialog>
</template>
<script setup>
import { computed, defineEmits, defineProps, ref } from "vue";
import ExSelectPicker from "@components/SelectPicker/ExSelectPicker.vue";
import Button from '@components/buttons/Button.vue';
import Icon from "@components/icons/Icon.vue";
import ExAvatar from "@components/common/ExAvatar.vue";
const emit = defineEmits(["input", "onSave"]);
const props = defineProps({
  value: {
    type: Boolean,
    default: false
  },
  accounts: {
    type: Array,
    default: []
  },
  user: {
    type: Object,
    default: {
      firstName: "User",
      lastName: "Account"
    }
  }
});
const selectedAccount = ref("");
const internalShow = computed({
  get() {
    return props.value;
  },
  set(newValue) {
    emit('input', newValue);
  }
});
const handleClose = () => {
  internalShow.value = false;
};
const onSave = () => {
  emit('onSave');
  internalShow.value = false;
};
</script>

<style scoped>
.ex-change-account-owner {
  border-radius: 12px !important;
}
.action_popup_message {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: #666666;
}
.ex-change-account-owner .step-label {
  margin-right: 10px;
  margin-top: 2px;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  color: #344054 !important;
}
.ex-change-account-owner .v-input--selection-controls__ripple {
  margin: 0px;
}
.ex-give-access-radio .v-input--selection-controls__ripple {
  width: 16px;
  height: 16px;
}
.action_popup_title {
  font-size: 18px;
  font-weight: 600;
  line-height: 28px;
  color: #101828;
}
.action_popup_message {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: #666666;
}
.cursor-pointer {
  cursor: pointer;
}
.ex-change-account-owner .modal-message {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}
.ex-change-account-owner .modal-message.text-left {
  text-align: left;
}
.ex-change-account-owner .modal-message.text-center {
  text-align: center;
}
.ex-change-account-owner .access-item {
  border: 2px solid white;
  border-radius: 12px;
}
.ex-change-account-owner .access-item:hover {
  border: 2px solid #7F56D9;
}
.ex-change-account-owner .access-item.item-selected {
  border: 2px solid #7F56D9;
}
.ex-change-account-owner .access-item .access-name {
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  text-align: left;
  color: #344054;
}
.ex-change-account-owner .access-item .access-description {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
  color: #475467;
}
.modal-icon {
  width: 48px;
  height: 48px !important;
  padding: 12px !important;
  border-radius: 10px;
  border: 1px solid #EAECF0;
  background-color: #FFFFFF !important;
  box-shadow: 0px 1px 2px 0px #1018280D;
  left: 22px;
  position: absolute;
}
.ex-change-account-owner .right-arrow-icon {
  top: 0px !important;
}
</style>